import React from 'react';
import PropTypes from 'prop-types';

const LevelOfEducationField = ({ value, onChange }) => {
  const educationOptions = [
    { label: 'Doctorate', value: 'p' },
    { label: "Master's or professional degree", value: 'm' },
    { label: "Bachelor's degree", value: 'b' },
    { label: 'Associate degree', value: 'a' },
    { label: 'Secondary/high school', value: 'hs' },
    { label: 'Junior secondary/junior high/middle school', value: 'jhs' },
    { label: 'Elementary/primary school', value: 'el' },
    { label: 'No formal education', value: 'none' },
    { label: 'Other education', value: 'other' },
  ];

  return (
    <div className="pgn__form-select">
      <label htmlFor="level_of_education">Level of Education</label>


      <select
        id="level_of_education"
        name="level_of_education"
        className="input-inline"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="" data-isdefault={true} selected={true}></option>
        {educationOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

LevelOfEducationField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default LevelOfEducationField;
