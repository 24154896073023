import React from 'react';
import PropTypes from 'prop-types';

const YearOfBirthField = ({ value, onChange }) => {
  const currentYear = new Date().getFullYear();
  const last100Years = Array.from({ length: 100 }, (_, index) => currentYear - index -1); // -1 to dont show current year

  return (
    <div className="pgn__form-select">
      <label htmlFor="year_of_birth">Year of birth</label>
      <select
        id="year_of_birth"
        name="year_of_birth"
        className="input-inline"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="" data-isdefault={true} selected={true}></option>
        {last100Years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

YearOfBirthField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default YearOfBirthField;
