import React from 'react';
import PropTypes from 'prop-types';

const GoalsField = ({ value, onChange }) => {
  return (
    <div className="pgn__form-textarea">
      <label htmlFor="goals">Tell us why you're interested in adpc</label>

      <textarea
        id="goals"
        name="goals"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        style={{width: '100%'}}
      />
    </div>
  );
};

GoalsField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default GoalsField;
