import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '../../common-components';

const GenderField = (props) => {
  const { value, onChange } = props;

  const genderOptions = [
    { label: 'Male', value: 'm' },
    { label: 'Female', value: 'f' },
    { label: 'Other/Prefer Not to Say', value: 'o' },
  ];


  return (
      <div class="pgn__form-select">
        <label htmlFor="gender">Gender</label>
        <select
          id="gender"
          name="gender"
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="" data-isdefault={true} selected={true}></option>

          {genderOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
           ))}

        </select>
      </div>
  );
};

GenderField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default GenderField;
