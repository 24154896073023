import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '../../common-components';

const JoinRecoftcField = (props) => {
  const { value, onChange } = props;

  const joinRecoftcOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  return (
      <div class="pgn__form-select">
        <label htmlFor="join_recoftc">Is this your first time to join training with RECOFTC?</label>
        <select
          id="join_recoftc"
          name="join_recoftc"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {joinRecoftcOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
  );
};

JoinRecoftcField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default JoinRecoftcField;
