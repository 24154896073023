import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '../../common-components';

const SectorField = (props) => {
  const { value, onChange } = props;

  const sectorOptions = [
    { label: 'Government', value: 'government' },
    { label: 'Civil Society', value: 'civil society' },
    { label: 'Regional organization', value: 'regional organization' },
    { label: 'Private sector', value: 'private sector' },
    { label: 'Other', value: 'other' },
  ];

  return (
    <div className="pgn__form-select">
      <label htmlFor="sector">Sector</label>
      <select
        id="sector"
        name="sector"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      >
        {sectorOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

SectorField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SectorField;
